import HomeNav from "components/nav/HomeNav";
import React from "react";

function Comingsoon({ className }) {
  return (
    <div className={className || ""}>
      <div className="text-gray-50 flex items-center justify-center payment-container my-10 py-16 h-3/5 grow">
        <h1 className="font-black-hawk text-gray-50 text-5xl animate__animated animate__fadeIn">
          Co<span className="text-red">min</span>g So
          <span className="text-red">on</span>...
        </h1>
      </div>
    </div>
  );
}

export default Comingsoon;
