import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import Comingsoon from "components/events/comingsoon/Comingsoon";
// import { useNavigate, NavLink, useLocation } from "react-router-dom";
// import TemporaryTokenContext from "components/context/TemporaryToken";
import USER from "components/context/UserContext";

// import { ssologin } from "components/login/ssologin";
// import { client_url } from "config/config";
import { logout } from "utils/auth";

// import LoginBtn from "components/login/Login";
// import LoginLoader from "components/loadings/Loading";
import Burger from "./Burger";
import { CSSTransition } from "react-transition-group";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";

export default function Nav() {
  // const { setTemporaryToken } = useContext(TemporaryTokenContext);
  // const { user, setUser } = useContext(USER);
  // const [loading, setLoading] = useState(false);
  const { user } = useContext(USER);
  const [openMenu, setOpenMenu] = useState(false);
  // const navigate = useNavigate();
  // const location = useLocation();

  // const tokenHandler = (token) => {
  //   ssologin(
  //     token,
  //     setLoading,
  //     setUser,
  //     navigate,
  //     setTemporaryToken,
  //     location.pathname
  //   );
  // };

  const logoutHandler = () => {
    logout();
    window.location.reload();
  };

  return (
    <>
      <div className="bg-primary_black px-2 md:px-5 py-4 flex items-center w-full sticky top-0 border-red border-b-2 drop-shadow-[0_0_0.5rem_rgba(208,11,2,0.6)] z-50">
        <NavLink to="/">
          <div className="ml-1 md:ml-4 flex items-center">
            <img
              src="/edge-logo.png"
              alt="edge2025-logo"
              className="w-[30px] h-[40px] md:w-[40px] md:h-[50px]"
            />
            <span
              className="text-white ml-4 font-bold drop-shadow-[0_0_0.2rem_red] text-3xl sm:text-3xl md:text-4xl"
              style={{ fontFamily: "SKCuber", fontWeight: "300" }}
            >
              EDG<span className="text-red">E</span> 20
              <span className="text-red">25</span>
            </span>
          </div>
        </NavLink>

        <div className="grow text-white flex justify-end md:mr-4 text-xs md:text-base nav-menu-lg">
          <div className="flex items-center px-4">
            <NavLink to="/about-us" className="nav-btn">
              About
            </NavLink>
            <NavLink to="/events" className="nav-btn">
              Events
            </NavLink>
            <NavLink to="/registernow" className="nav-btn">
              Register
            </NavLink>
            <NavLink to="/coming-soon" className="nav-btn">
              Schedule
            </NavLink>

            {/*             <NavLink to="/gallery" className="nav-btn">
                          Gallery
                        </NavLink> */}
            <NavLink to="/sponsors" className="nav-btn">
              Sponsors
            </NavLink>
            <NavLink to="/coming-soon" className="nav-btn">
              Blogs
            </NavLink>
            <NavLink to="/team" className="nav-btn">
              Team
            </NavLink>
            {/* {user.isLoggedIn && (
              <NavLink to="/profile" className="nav-btn">
                Profile
              </NavLink>
            )} */}
          </div>

          {/* {!loading && !user.isLoggedIn && (
            <LoginBtn clientUrl={client_url} confirm={tokenHandler} />
          )} */}

          {user.isLoggedIn && (
            <button
              onClick={logoutHandler}
              className="py-1 px-3 rounded-lg bg-transparent border-2 border-red drop-shadow-[0_0_0.3rem_red] mr-2"
            >
              Logout
            </button>
          )}
        </div>

        {/* <NavLink to="/edge-games">
          <div
            title="Game"
            className="border-2 border-[#7f0600] p-[6px] rounded-full text-white cursor-pointer hover:scale-110 transition ease-in-out duration-500 game-icon-lg"
          >
            <SportsEsportsIcon />
          </div>
        </NavLink> */}

        <div className="nav-menu-btn-sm grow flex justify-end pr-4 cur">
          <Burger open={openMenu} setOpen={setOpenMenu} />
        </div>
      </div>

      <CSSTransition
        in={openMenu}
        appear={false}
        timeout={300}
        classNames="details"
        unmountOnExit
      >
        <div className="bg-primary_black/90 fixed left-0 z-20 w-full text-white nav-menu-sm">
          <div className="relative bg-transparent text-2xl grow flex flex-col items-center mb-4 overflow-scroll">
            <div className="py-8 animate_animated animate_bounceInRight">
              <NavLink to="/coming-soon" target={"_blank"}>
                Schedule
              </NavLink>
            </div>
            <div className="py-8 animate_animated animate_bounceInLeft">
              <NavLink to="/registernow">
                <span className="text-red">R</span>egister Now
              </NavLink>
            </div>
            <div className="py-8 animate_animated animate_bounceInLeft">
              <NavLink to="/events">
                <span className="text-red">E</span>vents
              </NavLink>
            </div>
            <div className="py-8 animate_animated animate_bounceInRight">
              <NavLink to="/team">
                <span className="text-red">T</span>eam
              </NavLink>
            </div>
            {/*             <div className="py-8 animate_animated animate_bounceInLeft">
              <NavLink to="/coming-soon">
                <span className="text-red">G</span>allery
              </NavLink>
            </div> */}
            <div className="py-8 animate_animated animate_bounceInRight">
              <NavLink to="/sponsors">
                <span className="text-red">S</span>ponsors
              </NavLink>
            </div>
            <div className="py-8 animate_animated animate_bounceInLeft">
              <NavLink to="/clubs">
                <span className="text-red">A</span>bout
              </NavLink>
            </div>

            {/* {user.isLoggedIn && (
              <div className="py-8 animate_animated animate_bounceInRight">
                <NavLink to="/profile">
                  <span className="text-red">P</span>rofile
                </NavLink>
              </div>
            )} */}

            <div className="py-8 nav-menu-sm-login-btn">
              {/* {!loading && !user.isLoggedIn && (
                <LoginBtn clientUrl={client_url} confirm={tokenHandler} />
              )} */}

              {user.isLoggedIn && (
                <button
                  onClick={logoutHandler}
                  className="py-1 px-3 text-xl rounded-lg bg-transparent border-2 border-red drop-shadow-[0_0_0.3rem_red] mr-2"
                >
                  Logout
                </button>
              )}
            </div>

            {/* <NavLink to="/edge-games">
              <div
                title="Game"
                className="border-2 border-[#7f0600] px-5 py-1 rounded-full text-white cursor-pointer hover:scale-110 transition ease-in-out duration-500 game-icon-sm"
              >
                <SportsEsportsIcon sx={{ transform: "scale(1.2)" }} />
              </div>
            </NavLink> */}
          </div>
        </div>
      </CSSTransition>

      {/* {loading && <LoginLoader />} */}
    </>
  );
}
