import React from "react";

function Tnc() {
  return (
    <div className="flex justify-between px-2 z-[500] flex-row tnc">
      <a
        href="/tnc.html"
        target="_blank"
        rel="noreferrer"
        className="px-2"
        title="Terms and Conditions"
      >
        <img src="/tnc.png" alt="" className="h-6 w-6" />
      </a>
      <a
        href="/refundPolicy.html"
        target="_blank"
        rel="noreferrer"
        className="px-2"
        title="Refund Policy"
      >
        <img src="/refund.png" alt="" className="h-6 w-6" />
      </a>
      <a
        href="/privacyPolicy.html"
        target="_blank"
        rel="noreferrer"
        className="px-2"
        title="Privacy Policy"
      >
        <img src="/privacypolicy.png" alt="" className="h-6 w-6" />
      </a>
    </div>
  );
}

export default Tnc;
