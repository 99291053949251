import React, { useState, useEffect } from "react";
import HomeLoader from "components/loadings/HomeLoader";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import { CartContext } from "./components/context/CartContext";
import { UserContext } from "./components/context/UserContext";
import Home from "./pages/Home";
import NotFound from "./components/error/NotFound";
import ScrollToTop from "./utils/ScrollOnTop";
import About from "./components/home/About";
import Events from "./components/events/Event";
import Gallery from "./components/gallery/Gallery";
import Team from "./components/team/Team";
import SponsorPage from "./pages/Sponsors";
import { Game } from "./game/containers";
import NewEvent from "components/events/NewEvent";
import AdminDashboard from "components/admin/AdminDashboard";
import AdminPageLogin from "components/admin/AdminPageLogin";
import DemoHome from "pages/DemoHome";
import Clubs from "components/clubs/Clubs";
import Registration from "pages/Registration";
import Comingsoon from "components/events/comingsoon/Comingsoon";
import RegisterForm from "components/registration/RegisterForm";
import Schedule from "components/admin/Shedule";
import Blogs from "components/blogs/blogs";

const geekuser = window.localStorage.getItem("geekuser");

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      const preloaderEl = document.getElementById("preloader");
      if (preloaderEl) {
        preloaderEl.classList.add("fade-out");
      }

      setTimeout(() => {
        setLoading(false);
      }, 600);
    }, 600);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <HomeLoader />;
  }

  return (
    <BrowserRouter>
      <UserContext>
        <CartContext>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/demohome" element={<DemoHome />} />
            <Route path="/coming-soon" element={<Comingsoon />} />
            <Route path="/events" element={<NewEvent />} />
            <Route path="/oldevents" element={<Events />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/team" element={<Team />} />
            <Route path="/about-us" element={<Clubs />} />
            <Route path="/clubs" element={<Clubs />} />
            <Route path="/sponsors" element={<SponsorPage />} />
            <Route path="/edge-games" element={<Game />} />
            <Route path="/about" element={<About />} />
            <Route path="/adminlogin" element={<AdminPageLogin />} />
            <Route path="/registernow" element={<Registration />} />
            <Route path="/eventregister" element={<RegisterForm />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/blogs" element={<Blogs />} />

            <Route path="*" element={<NotFound />} />
            {/* Protected Routing */}

            <Route path="/admindashboard" element={<AdminDashboard />} />
          </Routes>
        </CartContext>
      </UserContext>
    </BrowserRouter>
  );
}

export default App;
