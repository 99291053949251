import React, { useState } from "react";
import swal from "sweetalert";
import Nav from "components/nav/Navbar";
import Footer from "components/footer/Footer";

const yearList = ["1st", "2nd", "3rd", "4th", "5th", "NA"];

function Option({ name }) {
  return <option value={name}>{name}</option>;
}

export default function RegisterForm({ onSubmit }) {
  const [dept, setDept] = useState("");
  const [year, setYear] = useState("");
  const [institute, setInstitute] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    if (!dept && !year)
      swal("Oops!", "Please Fill Your Details Before Registering", "warning");
    else if (!dept) swal("Oops!", "Please Select Your Department", "warning");
    else if (!year) swal("Oops!", "Please Select Your Year", "warning");
    else if (onSubmit) onSubmit({ year, dept, institute });
    else swal("Oops!", "Something Went Wrong! Please Try Again", "warning");
  }

  return (
    <>
      <Nav />
      <form className="p-6 bg-[#FFFFFF21] backdrop-blur-lg min-w-[90%] sm:min-w-[420px] z-10 shadow-2xl border border-gray-500 flex flex-col items-center">
        <h1 className="text-center py-7 px-2 font-black-hawk text-7xl">
          Ed<span className="text-red">ge 20</span>25
        </h1>

        {/* Input Fields */}
        <div className="w-full flex flex-col items-center gap-6 mt-6 text-xl font-bold text-white">
          {/* Name */}
          <div className="flex flex-col w-full items-center">
            <label htmlFor="name" className="mb-2 uppercase tracking-wider">
              Enter your Name
            </label>
            <input
              type="text"
              id="name"
              placeholder="Enter your name"
              value={dept}
              onChange={(e) => setDept(e.target.value)}
              required
              className="w-[90%] sm:w-[80%] text-black p-2 rounded-md text-center border-2 border-red-500 bg-white focus:outline-none focus:ring-2 focus:ring-red-400"
            />
          </div>

          {/* Department */}
          <div className="flex flex-col w-full items-center">
            <label htmlFor="dept" className="mb-2 uppercase tracking-wider">
              Select Department
            </label>
            <input
              type="text"
              id="dept"
              placeholder="Enter your department"
              value={dept}
              onChange={(e) => setDept(e.target.value)}
              required
              className="w-[90%] sm:w-[80%] text-black p-2 rounded-md text-center border-2 border-red-500 bg-white focus:outline-none focus:ring-2 focus:ring-red-400"
            />
          </div>

          {/* Year */}
          <div className="flex flex-col w-full items-center">
            <label htmlFor="year" className="mb-2 uppercase tracking-wider">
              Select Year
            </label>
            <select
              name="year"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              id="year"
              className="w-[95%] sm:w-[80%] h-12 text-black py-3 px-4 rounded-md text-lg sm:text-base border-2 border-red-500 bg-white 
             focus:outline-none focus:ring-2 focus:ring-red-400 transition-all duration-300"
              required
            >
              <option value="">--Select--</option>
              {yearList.map((item, idx) => (
                <Option name={item} key={idx.toString()} />
              ))}
            </select>
          </div>

          {/* Institution */}
          <div className="flex flex-col w-full items-center">
            <label
              htmlFor="institute"
              className="mb-2 uppercase tracking-wider"
            >
              Select Institution
            </label>
            <input
              type="text"
              id="institute"
              placeholder="Enter your institution"
              value={institute}
              onChange={(e) => setInstitute(e.target.value)}
              required
              className="w-[90%] sm:w-[80%] text-black p-2 rounded-md text-center border-2 border-red-500 bg-white focus:outline-none focus:ring-2 focus:ring-red-400"
            />
          </div>
        </div>

        {/* Register Button */}
        <div className="flex justify-center py-6 w-full">
          <button
            onClick={handleSubmit}
            className="w-[90%] sm:w-[80%] rounded-xl border-2 border-red-500 bg-red text-white px-4 py-3 text-lg font-extrabold uppercase tracking-widest 
            transition-all duration-300 shadow-lg 
            hover:bg-red-500/30 hover:backdrop-blur-md hover:border-white hover:text-white"
          >
            Register Now 🚀
          </button>
        </div>
      </form>
      <Footer />
    </>
  );
}
