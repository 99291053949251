import React, { useState, useEffect, useRef } from "react";
import { X } from "lucide-react";
import character1 from "../assets/character/SVG/1.svg";
import character2 from "../assets/character/SVG/2.svg";
import character3 from "../assets/character/SVG/3.svg";
import character4 from "../assets/character/SVG/4.svg";
import character5 from "../assets/character/lux.png";

const Omnitrix = () => {
  const [activeCharacter, setActiveCharacter] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [rotating, setRotating] = useState(false);
  const [rotationDegree, setRotationDegree] = useState(0);
  const rotationRef = useRef(null);

  const characters = [
    {
      name: "Elufus",
      image: character1,
      description:
        "For what sacred calling did Elufus tread, to the Lands of Time where mortals dread? For what decree was he bound to weave, along the Thread where fates deceive? For what solemn trial did he rise alone, the herald of Walkers, the bearer of stone, shouldering burdens no soul could share, to mend the Sands laid lost and bare? Was it the will of ancients deep, whispered where the echoes sleep? Or destiny’s hand, unyielding, vast, a force unbroken, a shadow cast? O Elufus, wanderer bright, your name shall dance in endless night, sung by gods, by tongues untamed, a sentinel bound, yet never named.",
    },
    {
      name: "Chronos",
      image: character2,
      description:
        "Before the first breath of the cosmos, before the stars dared to burn, there was Chronos. He is neither past nor future, but the weaver of both—a being beyond the constraints of gods or men. His form is shifting sand, a river of golden echoes that never settles, never rests. He is the silent force behind every ticking moment, the sentinel of an order that none may defy. When Elufus stood at the edge of time’s great loom, Chronos turned his endless gaze upon him. “You seek to mend what was broken,” he murmured, “but tell me, traveler—can you bear the weight of eternity’s hand?” Even he, the keeper of all things fleeting, knows that fate is not written in stone. It is written in sacrifice.",
    },
    {
      name: "Umbra",
      image: character3,
      description:
        "In the abyss where no dawn treads, where light is but a fractured memory, Umbra reigns. The first and the last of shadow’s kin, his form is a silhouette against the dying stars, his voice the hush between thunderclaps. When Elufus walked the Veil of the Forsaken, it was Umbra who stood before him—an omen draped in the cosmos' forgotten ink. What is time, he whispered, if not the absence of light? From his grasp spilled the echoes of ages undone, weaving nightmares into the fabric of what once was. He is the keeper of lost fates, the warden of the void, and yet, even he cannot outrun the cycle that binds him to the ever-turning wheel of time.",
    },
    {
      name: "Lux",
      image: character4,
      description:
        "Above a world of smog-choked heavens and steel-clad streets, where circuits pulse like veins and eternity hums in digital whispers, Lux watches. The Architect of the Neon Dominion, he speaks in streams of code and shapes reality with the flick of a cybernetic thought. His temples are skyscrapers, his prophets are machines, and his gospel is the flicker of dying screens. “To know time is to rewrite it,” he mused when Elufus arrived, stepping from the old world into a realm where fate is a sequence of ones and zeroes. Here, destiny is no longer bound by parchment or stars—it is uploaded, modified, rewritten. But even the mightiest algorithms cannot break the thread woven by forces older than light itself.",
    },
    {
      name: "Lux",
      image: character5,
      description:
        "Above a world of smog-choked heavens and steel-clad streets, where circuits pulse like veins and eternity hums in digital whispers, Lux watches. The Architect of the Neon Dominion, he speaks in streams of code and shapes reality with the flick of a cybernetic thought. His temples are skyscrapers, his prophets are machines, and his gospel is the flicker of dying screens. “To know time is to rewrite it,” he mused when Elufus arrived, stepping from the old world into a realm where fate is a sequence of ones and zeroes. Here, destiny is no longer bound by parchment or stars—it is uploaded, modified, rewritten. But even the mightiest algorithms cannot break the thread woven by forces older than light itself.",
    },
  ];

  // Clean up animation frame on unmount
  useEffect(() => {
    return () => {
      if (rotationRef.current) {
        cancelAnimationFrame(rotationRef.current);
      }
    };
  }, []);

  const handleCharacterClick = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const rotateToCharacter = (index) => {
    if (rotating) return;

    setRotating(true);

    // Calculate target rotation (each character is 90 degrees apart)
    const targetRotation = index * 90;
    let currentRotation = rotationDegree % 360;

    // Normalize current rotation to be between 0-359
    if (currentRotation < 0) currentRotation += 360;

    // Find shortest path to target
    let deltaRotation = targetRotation - currentRotation;

    // Adjust for shortest path
    if (Math.abs(deltaRotation) > 180) {
      deltaRotation =
        deltaRotation > 0 ? deltaRotation - 360 : deltaRotation + 360;
    }

    const startRotation = rotationDegree;
    const endRotation = rotationDegree + deltaRotation;
    const duration = 1000; // 1 second
    const startTime = performance.now();

    const animateRotation = (currentTime) => {
      const elapsedTime = currentTime - startTime;

      if (elapsedTime < duration) {
        // Easing function for smooth animation
        const progress = 1 - Math.pow(1 - elapsedTime / duration, 3); // Cubic ease-out
        const newRotation = startRotation + deltaRotation * progress;
        setRotationDegree(newRotation);
        rotationRef.current = requestAnimationFrame(animateRotation);
      } else {
        // Animation complete
        setRotationDegree(endRotation);
        setActiveCharacter(index);
        setRotating(false);
      }
    };

    rotationRef.current = requestAnimationFrame(animateRotation);
  };

  return (
    <div className="flex items-center justify-center my-32">
      <div className="relative">
        <div className="w-48 h-96 bg-amber-700 rounded-full absolute top-32 left-1/2 transform -translate-x-1/2 -z-10"></div>
        <div className="w-64 h-40 bg-amber-800 rounded-t-full absolute top-64 left-1/2 transform -translate-x-1/2 -z-10"></div>

        <div className="relative w-[300px] h-[300px] perspective-1000">
          <div className="absolute inset-0 bg-gray-900 rounded-full shadow-2xl transform translate-y-1"></div>

          <div className="absolute inset-0 bg-gray-700 rounded-full border-8 border-gray-800 shadow-inner"></div>

          <div
            className="absolute inset-2 bg-gradient-to-br from-gray-600 to-gray-800 rounded-full border-8 border-gray-600 shadow-lg flex items-center justify-center transform transition-transform duration-300"
            style={{
              transform: `rotateZ(${rotationDegree}deg)`,
              boxShadow:
                "inset 0 0 15px rgba(0, 0, 0, 0.5), 0 5px 15px rgba(0, 0, 0, 0.3)",
            }}
          >
            <div
              className="absolute inset-0 rounded-full"
              style={{
                background:
                  "repeating-conic-gradient(from 0deg, rgba(255, 0, 0, 0.3), rgba(255, 0, 0, 0) 5deg, rgba(255, 0, 0, 0.3) 10deg)",
              }}
            ></div>

            <div
              className="absolute w-60 h-60 bg-gradient-to-br from-gray-800 to-gray-700 rounded-full border-4 border-red shadow-lg shadow-red flex items-center justify-center"
              style={{
                boxShadow:
                  "inset 0 0 20px rgba(0, 200, 0, 0.3), 0 0 15px rgba(0, 255, 0, 0.3)",
              }}
            >
              <div
                className="relative w-44 h-44 bg-black rounded-full flex items-center justify-center overflow-hidden cursor-pointer transform -translate-y-1"
                onClick={handleCharacterClick}
                style={{
                  boxShadow:
                    "inset 0 0 30px rgba(255, 0, 0, 0.2), 0 0 15px rgba(255, 0, 0, 0.6)",
                }}
              >
                <div className="absolute inset-2 bg-black rounded-full border-2 border-white"></div>

                <div className="absolute inset-6 bg-orange-900/30 rounded-full z-10 flex items-center justify-center">
                  <div className="w-24 h-24 rounded-full bg-slate-400/30 animate-pulse"></div>
                </div>

                <div className="absolute inset-0 flex items-center justify-center z-20">
                  <div className="w-16 h-16 rounded-full bg-gray-900 border-4 border-orange-700 flex items-center justify-center">
                    <div className="w-2 h-8 bg-rose-700"></div>
                    <div className="w-8 h-2 bg-rose-500"></div>
                  </div>
                </div>
              </div>
            </div>

            <button
              className="absolute top-0 left-1/2 transform -translate-x-1/2 w-12 h-12 bg-gradient-to-br from-orange-400 to-orange-700 rounded-full border-2 border-gray-300 shadow-md z-20 hover:from-orange-600 hover:to-orange-800 focus:outline-none"
              onClick={() => rotateToCharacter(0)}
              style={{
                boxShadow:
                  "inset 0 -3px 8px rgba(0, 0, 0, 0.4), 0 3px 5px rgba(0, 0, 0, 0.5)",
              }}
            />
            <button
              className="absolute right-2 top-1/2 transform -translate-y-1/2 w-12 h-12 bg-gradient-to-br from-yellow-500 to-slate-700 rounded-full border-2 border-gray-300 shadow-md z-20 hover:from-yellow-600 hover:to-yellow-800 focus:outline-none"
              onClick={() => rotateToCharacter(1)}
              style={{
                boxShadow:
                  "inset 0 -3px 8px rgba(0, 0, 0, 0.4), 0 3px 5px rgba(0, 0, 0, 0.5)",
              }}
            />
            <button
              className="absolute bottom-4 left-1/3 transform -translate-x-1/2 w-12 h-12 bg-gradient-to-br from-black to-white rounded-full border-2 border-gray-300 shadow-md z-20 hover:from-slate-500 hover:to-slate-700 focus:outline-none"
              onClick={() => rotateToCharacter(2)}
              style={{
                boxShadow:
                  "inset 0 -3px 8px rgba(0, 0, 0, 0.4), 0 3px 5px rgba(0, 0, 0, 0.5)",
              }}
            />
            <button
              className="absolute left-2 top-1/2 transform -translate-y-1/2 w-12 h-12 bg-gradient-to-br from-black to-orange-700 rounded-full border-2 border-gray-300 shadow-md z-20 hover:from-black hover:to-orange-800 focus:outline-none"
              onClick={() => rotateToCharacter(3)}
              style={{
                boxShadow:
                  "inset 0 -3px 8px rgba(0, 0, 0, 0.4), 0 3px 5px rgba(0, 0, 0, 0.5)",
              }}
            />
            <button
              className="absolute left-44 top-56 transform -translate-y-1/2 w-12 h-12 bg-gradient-to-br from-black to-yellow-600 rounded-full border-2 border-gray-300 shadow-md z-20 hover:from-black hover:to-yellow-800 focus:outline-none"
              onClick={() => rotateToCharacter(4)}
              style={{
                boxShadow:
                  "inset 0 -3px 8px rgba(0, 0, 0, 0.4), 0 3px 5px rgba(0, 0, 0, 0.5)",
              }}
            />
          </div>

          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full pointer-events-none z-30">
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-full w-64 h-64">
              <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-24 h-3 bg-rose-400 rounded-full blur-md"></div>

              <div className="absolute bottom-0 w-full h-full flex justify-center items-end">
                <img
                  src={characters[activeCharacter].image}
                  alt={characters[activeCharacter].name}
                  className="w-48 h-48 object-contain filter holo-effect"
                />
              </div>
            </div>
          </div>

          <div
            className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 w-40 h-16 bg-gradient-to-b from-gray-600 to-gray-700 rounded-b-2xl border-4 border-gray-800 -z-10"
            style={{ boxShadow: "inset 0 5px 10px rgba(255, 0, 0, 0.5)" }}
          ></div>
        </div>
      </div>

      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
          <div
            className="bg-gray-800 rounded-lg p-6 max-w-md border-2 border-rose-500 animate-in"
            style={{ boxShadow: "0 0 30px rgba(255, 0, 0, 0.3)" }}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold text-rose-400 font-black-hawk">
                {characters[activeCharacter].name}
              </h2>
              <button
                onClick={closePopup}
                className="text-gray-400 hover:text-white"
              >
                <X size={24} />
              </button>
            </div>
            <div className="mb-4 flex justify-center">
              <div
                className="w-32 h-32 rounded-full overflow-hidden border-2 border-rose-800"
                style={{ boxShadow: "inset 0 0 20px rgba(255, 0, 0, 0.5)" }}
              >
                <img
                  src={characters[activeCharacter].image}
                  alt={characters[activeCharacter].name}
                  className="w-full h-full object-contain"
                />
              </div>
            </div>
            <p className="text-gray-300">
              {characters[activeCharacter].description}
            </p>
          </div>
        </div>
      )}

      {/* Add keyframes for hologram effect */}
      <style jsx>{`
        .holo-effect {
          animation: hologramFlicker 3s ease-in-out infinite;
          opacity: 0.97;
          filter: brightness(1.5) drop-shadow(0 0 5px rgba(255, 0, 0, 0.8));
        }

        @keyframes hologramFlicker {
          0% {
            opacity: 0.7;
            transform: scaleY(0.98);
          }
          25% {
            opacity: 0.9;
            transform: scaleY(1.02);
          }
          30% {
            opacity: 0.7;
            transform: scaleY(0.99);
          }
          35% {
            opacity: 0.9;
            transform: scaleY(1);
          }
          70% {
            opacity: 0.8;
            transform: scaleY(1.01);
          }
          75% {
            opacity: 0.6;
            transform: scaleY(0.98);
          }
          80% {
            opacity: 0.8;
            transform: scaleY(1);
          }
          100% {
            opacity: 0.7;
            transform: scaleY(0.98);
          }
        }

        .perspective-1000 {
          perspective: 1000px;
        }

        .animate-in {
          animation: popIn 0.3s ease-out forwards;
        }

        @keyframes popIn {
          0% {
            transform: scale(0.9);
            opacity: 0;
          }
          100% {
            transform: scale(1);
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
};

export default Omnitrix;
