import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
// import { useNavigate, NavLink, useLocation } from "react-router-dom";
// import TemporaryTokenContext from "components/context/TemporaryToken";
import USER from "components/context/UserContext";

// import { ssologin } from "components/login/ssologin";
// import { client_url } from "config/config";
import { logout } from "utils/auth";

// import LoginBtn from "components/login/Login";
// import LoginLoader from "components/loadings/Loading";
import Burger from "./Burger";
import { CSSTransition } from "react-transition-group";
import { useNavigate } from "react-router-dom";

export default function HomeNav() {
  const geekUser = window.localStorage.getItem("geekuser");
  // const { setTemporaryToken } = useContext(TemporaryTokenContext);
  // const { user, setUser } = useContext(USER);
  // const [loading, setLoading] = useState(false);
  const { user } = useContext(USER);
  const [openMenu, setOpenMenu] = useState(false);
  // const navigate = useNavigate();
  // const location = useLocation();

  // const tokenHandler = (token) => {
  //   ssologin(
  //     token,
  //     setLoading,
  //     setUser,
  //     navigate,
  //     setTemporaryToken,
  //     location.pathname
  //   );
  // };
  const navigate = useNavigate();

  const logoutHandler = () => {
    window.localStorage.removeItem("geekuser");
    navigate("/");
    window.location.reload();
  };

  return (
    <>
      <div className=" px-2 md:px-5 py-4 flex items-center w-full sticky top-0  drop-shadow-[0_0_0.5rem_rgba(208,11,2,0.6)] z-50">
        <NavLink to="/">
          <div className="ml-1 md:ml-4 flex items-center">
            <img
              src="/edge-logo.png"
              alt="edge2025-logo"
              className="w-[30px] h-[40px] md:w-[40px] md:h-[50px]"
            />
            <span
              className="text-white ml-4 font-bold drop-shadow-[0_0_0.2rem_red] text-3xl sm:text-3xl md:text-4xl"
              style={{ fontFamily: "SKCuber", fontWeight: "300" }}
            >
              EDG<span className="text-red">E</span> 20
              <span className="text-red">25</span>
            </span>
          </div>
        </NavLink>

        <div className="grow text-white flex justify-end md:mr-4 text-xs md:text-base nav-menu-lg">
          <div className="flex items-center px-4">
            <NavLink to="/about-us" className="nav-btn">
              About
            </NavLink>
            <NavLink to="/events" className="nav-btn">
              Events
            </NavLink>
            <NavLink to="/registernow" className="nav-btn">
              Register
            </NavLink>
            <NavLink to="/team" className="nav-btn">
              Team
            </NavLink>
            <NavLink to="/schedule" className="nav-btn">
              Schedule
            </NavLink>

            {/*             <NavLink to="/gallery" className="nav-btn">
              Gallery
            </NavLink> */}
            <NavLink to="/sponsors" className="nav-btn">
              Sponsors
            </NavLink>
            <NavLink to="/blogs" className="nav-btn">
              Blogs
            </NavLink>

            {geekUser == "Admin" ? (
              <>
                <NavLink to="/admindashboard" className="nav-btn">
                  Admin
                </NavLink>
                <button onClick={logoutHandler} className="nav-btn text-sm">
                  Logout
                </button>
              </>
            ) : (
              <NavLink to="/adminlogin" className="nav-btn">
                Admin
              </NavLink>
            )}

            {/* {user.isLoggedIn && (
              <NavLink to="/profile" className="nav-btn">
                Profile
              </NavLink>
            )} */}
          </div>

          {/* {!loading && !user.isLoggedIn && (
            <LoginBtn clientUrl={client_url} confirm={tokenHandler} />
          )} */}

          {user.isLoggedIn && (
            <button
              onClick={logoutHandler}
              className="py-1 px-3 rounded-lg bg-transparent border-2 border-red drop-shadow-[0_0_0.3rem_red] mr-2"
            >
              Logout
            </button>
          )}
        </div>

        {/* <NavLink to="/edge-games">
          <div
            title="Game"
            className="border-2 border-[#7f0600] p-[6px] rounded-full text-white cursor-pointer hover:scale-110 transition ease-in-out duration-500 game-icon-lg"
          >
            <SportsEsportsIcon />
          </div>
        </NavLink> */}

        <div className="nav-menu-btn-sm grow flex justify-end pr-4 cur">
          <Burger open={openMenu} setOpen={setOpenMenu} />
        </div>
      </div>

      <CSSTransition
        in={openMenu}
        appear={false}
        timeout={300}
        classNames="details"
        unmountOnExit
      >
        <div className="bg-primary_black/90 fixed left-0 z-20 w-full text-white nav-menu-sm">
          <div className="relative bg-transparent text-2xl grow flex flex-col items-center mb-4 overflow-scroll">
            <div className="py-8 animate__animated animate__bounceInLeft">
              <NavLink to="/about-us">
                <span className="text-red">A</span>bout
              </NavLink>
            </div>
            <div className="py-8 animate__animated animate__bounceInLeft">
              <NavLink to="/events">
                <span className="text-red">E</span>vents
              </NavLink>
            </div>
            <div className="py-8 animate__animated animate__bounceInLeft">
              <NavLink to="/registernow" className="nav-btn">
                <span className="text-red">R</span>egister Now
              </NavLink>
            </div>
            <div className="py-8 animate__animated animate__bounceInRight">
              <NavLink to="/schedule">Schedule</NavLink>
            </div>

            <div className="py-8 animate__animated animate__bounceInRight">
              <NavLink to="/team">
                <span className="text-red">T</span>eam
              </NavLink>
            </div>
            <NavLink to="/blogs" className="nav-btn">
              Blogs
            </NavLink>
            {/*             <div className="py-8 animate__animated animate__bounceInLeft">
              <NavLink to="/gallery">
                <span className="text-red">G</span>allery
              </NavLink>
            </div> */}
            <div className="py-8 animate__animated animate__bounceInRight">
              <NavLink to="/sponsors">
                <span className="text-red">S</span>ponsors
              </NavLink>
            </div>

            {geekUser == "Admin" ? (
              <>
                <div className="py-8 animate__animated animate__bounceInLeft">
                  <NavLink to="/admindashboard" className="nav-btn">
                    <span className="text-red">A</span>dmin
                  </NavLink>
                </div>
                <button onClick={logoutHandler} className="nav-btn text-xs">
                  <span className="text-red">L</span>ogout
                </button>
              </>
            ) : (
              <div className="py-8 animate__animated animate__bounceInLeft">
                <NavLink to="/adminlogin" className="nav-btn">
                  <span className="text-red">A</span>dmin
                </NavLink>
              </div>
            )}
            {/* {user.isLoggedIn && (
              <div className="py-8 animate__animated animate__bounceInRight">
                <NavLink to="/profile">
                  <span className="text-red">P</span>rofile
                </NavLink>
              </div>
            )} */}

            <div className="py-8 nav-menu-sm-login-btn">
              {/* {!loading && !user.isLoggedIn && (
                <LoginBtn clientUrl={client_url} confirm={tokenHandler} />
              )} */}

              {user.isLoggedIn && (
                <button
                  onClick={logoutHandler}
                  className="py-1 px-3 text-xl rounded-lg bg-transparent border-2 border-red drop-shadow-[0_0_0.3rem_red] mr-2"
                >
                  Logout
                </button>
              )}
            </div>

            {/*             <NavLink to="/edge-games">
              <div
                title="Game"
                className="border-2 border-[#7f0600] px-5 py-1 rounded-full text-white cursor-pointer hover:scale-110 transition ease-in-out duration-500 game-icon-sm"
              >
                <SportsEsportsIcon sx={{ transform: "scale(1.2)" }} />
              </div>
            </NavLink> */}
          </div>
        </div>
      </CSSTransition>

      {/* {loading && <LoginLoader />} */}
    </>
  );
}
