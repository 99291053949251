import React, { useContext, useEffect, useState } from "react";
import USER from "components/context/UserContext";
import { useSearchParams } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import { CSSTransition } from "react-transition-group";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useHorizontalScroll from "utils/useHorizontalScroll";
import EventItem from "./EventItem";
import Comingsoon from "./comingsoon/Comingsoon";
import EventDetails from "./EventDetails";
import Loading from "components/loadings/Loading";

const clubCards = [
  { id: "1", clubimage: "/images/club_cards/ciic.svg", title: "CIIC" },
  { id: "2", clubimage: "/images/club_cards/curbrain.svg", title: "Curbrain" },
  { id: "3", clubimage: "/images/club_cards/createit.svg", title: "Create IT" },
  {
    id: "4",
    clubimage: "/images/club_cards/elevation.svg",
    title: "Elevation",
  },
  {
    id: "5",
    clubimage: "/images/club_cards/cybercrusade.svg",
    title: "Cyber Crusade",
  },
  {
    id: "6",
    clubimage: "/images/club_cards/foodforfun.svg",
    title: "Food For Fun",
  },
  {
    id: "7",
    clubimage: "/images/club_cards/funevents.svg",
    title: "Fun Events",
  },
  {
    id: "8",
    clubimage: "/images/club_cards/geekdesign.svg",
    title: "Geek Design",
  },
  { id: "9", clubimage: "/images/club_cards/infocus.svg", title: "In Focus" },
  { id: "10", clubimage: "/images/club_cards/newron.svg", title: "Newron" },
  { id: "11", clubimage: "/images/club_cards/rrc.svg", title: "RRC" },
];

export default function Events() {
  const { user } = useContext(USER);
  const [searchParams, setSearchParams] = useSearchParams();
  const [curData, setCurData] = useState("clubs");
  const [selectedClub, setSelectedClub] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [REGISTERED_EVENTS, SET_REGISTERED_EVENTS] = useState([]);

  useEffect(() => {
    if (user.isLoggedIn && user.data?.registeredEvents) {
      SET_REGISTERED_EVENTS(user.data.registeredEvents);
    }
  }, [user]);

  useEffect(() => {
    const clubId = searchParams.get("club");
    const eventCode = searchParams.get("event");

    if (eventCode) {
      setSelectedEvent(eventCode);
      setShowDetails(true);
    } else {
      setSelectedEvent(null);
      setShowDetails(false);
    }

    if (!clubId) {
      setCurData("clubs");
      setSelectedClub(null);
      return;
    }

    const club = clubCards.find((item) => item.id === clubId);
    if (club) {
      setSelectedClub(club);
      setCurData("events");
    } else {
      searchParams.delete("club");
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  function closeModal() {
    searchParams.delete("event");
    setSearchParams(searchParams);
  }

  function goBackHandler() {
    searchParams.delete("club");
    setSearchParams(searchParams);
    setSelectedClub(null);
    setCurData("clubs");
  }

  const [elRef, scrollLeft, scrollRight] = useHorizontalScroll();

  return (
    <div className="event-gredient md:flex-row flex-col-reverse overflow-x-auto flex w-[100vw] h-[100%] relative">
      {curData !== "clubs" && (
        <button
          title="Back"
          className="text-gray-300 text-3xl sm:text-4xl rounded mr-4 drop-shadow-[0_0_0.3rem_red] transition-all min-w-max flex align-center absolute top-4 lg:top-8 left-6 lg:left-20 z-10"
          onClick={goBackHandler}
        >
          <ArrowBackIcon fontSize="inherit" />
        </button>
      )}

      <div className="clubs-heading">
        <div
          className="p-2 cursor-pointer hover:translate-x-[5px] transition-all duration-[0.5s] bg-red opacity-75 rounded-[50%] text-white text-2xl"
          onClick={scrollLeft}
        >
          <AiOutlineArrowRight />
        </div>

        <span className="md:rotate-[-90deg]">
          <span className="text-red">{curData === "clubs" ? "C" : "E"}</span>
          {curData === "clubs" ? "lubs" : "vents"}
        </span>

        <div
          className="p-2 cursor-pointer rotate-180 hover:translate-x-[-5px] transition-all duration-[0.5s] bg-red opacity-75 rounded-[50%] text-white text-2xl"
          onClick={scrollRight}
        >
          <AiOutlineArrowRight />
        </div>
      </div>

      <div
        ref={elRef}
        className="event-gredient overflow-x-auto flex w-[100vw] h-[90vh]"
      >
        {curData === "clubs" ? (
          <>
            {clubCards.map((club, idx) => (
              <EventItem
                key={club.id}
                data={club}
                idx={idx}
                onClick={() => {
                  searchParams.set("club", club.id);
                  setSearchParams(searchParams);
                }}
              />
            ))}
          </>
        ) : selectedClub ? (
          <Comingsoon className="grow" />
        ) : null}
      </div>

      <CSSTransition
        in={showDetails}
        timeout={500}
        classNames="details"
        unmountOnExit
      >
        <EventDetails
          eventCode={selectedEvent}
          close={closeModal}
          registered={REGISTERED_EVENTS.includes(selectedEvent)}
        />
      </CSSTransition>
    </div>
  );
}
