import React from "react";
import { ReactComponent as Hexagon } from "assets/icons/hexagon-half.svg";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <div className="bg-secondary_black w-full py-4 px-8 md:px-16 text-sm sm:text-xl">
      <div className="text-white py-6">
        <h2 className="text-center text-3xl drop-shadow-[0_0_0.1rem_red]">
          About INTRA
          <span className="text-red">'25</span>
        </h2>
        <p className=" text-justify mt-4">
          It can be intimidating to face the{" "}
          <strong>
            second largest Techno-Management fest of Eastern India
          </strong>{" "}
          without any practice. Hence to give you the best possible chance of
          playing to your potential, Geekonix brings to you{" "}
          <span className="text-red text-xl">INTRA COLLEGE FEST</span>{" "}
          exclusively for the fresher's and second years of{" "}
          <strong className="italic text-xl">TMSL ONLY</strong>, for you to have
          a first-hand experience and a glipmse of what EDGE'25 holds for you in
          the future.
        </p>

        <h1 className="text-red text-center text-base md:text-3xl my-12">
          Dates: 4th, 5th, 6th March 2025
        </h1>

        <p className="text-justify">
          Mark the dates and block your calendar to participate in this
          full-fledged version of EDGE, to know your standing. Refer to the
          below mentions instructions to have a clear idea about the process of
          participation.
        </p>
      </div>

      <div className="flex items-center justify-center grow">
        <div className="items-center my-6 text-white drop-shadow-[0_0_0.1rem_red] text-xl sm:text-2xl md:text-3xl">
          <h1>General Instructions</h1>
        </div>
      </div>

      <div className="flex justify-start grow">
        <div className="relative w-[30px]">
          <div className="absolute bg-[#7f0600] top-0 left-[10px] h-full w-[2px] overflow-hidden drop-shadow-[0_0_0.2rem_red]">
            <div className="rules-scanner "></div>
          </div>
        </div>

        <div className="flex text-white pl-[10px] text-justify text-sm sm:text-xl">
          <ul className="">
            <li className="flex items-start py-1">
              <span className="my-1">
                <Hexagon color="#7f0600" />
              </span>
              <span className="pl-2">
                REGISTRATIONS ARE OPEN NOW. Pay Rs. 149 and participate in all
                the events!
              </span>
            </li>
            <li className="flex items-start py-1">
              <span className="my-1">
                <Hexagon color="#7f0600" />
              </span>
              <span className="pl-2">
                For OFFLINE registration, visit the EDGE room (Room no. 401)
                from 12pm onwards.
              </span>
            </li>
            <li className="flex items-start py-1">
              <span className="my-1">
                <Hexagon color="#7f0600" />
              </span>
              <span className="pl-2">
                For <strong>online transaction</strong>: Scan the QR code or use
                the ID given in the poster. Send the confirmation screenshot
                containing UPI/TRANSACTION ID of yours to any of the following
                mentioned numbers:
                <div className="flex justify-center p-4">
                  <span className=" pt-2 flex flex-col justify-center text-xs sm:text-xl lg:grid lg:grid-cols-2 lg:w-[70%] ">
                    <p className="">
                      Akshita:{" "}
                      <a
                        href="tel:+916202391911"
                        className="hover:text-red transition-all"
                      >
                        +91 62023 91911
                      </a>
                    </p>
                    <p>
                      Aditya:{" "}
                      <a
                        href="tel:+918420818780"
                        className="hover:text-red transition-all"
                      >
                        +91 84208 18780
                      </a>
                    </p>
                  </span>
                </div>
              </span>
            </li>
            <li className="flex items-start py-1">
              <span className="my-1">
                <Hexagon color="#7f0600" />
              </span>
              <span className="pl-2">
                For ONLINE registration, log in to{" "}
                <a
                  // href="https://intra.edg.co.in/events"
                  className="text-red"
                  target="_blank"
                  rel="noreferrer"
                >
                  intra.edg.co.in
                </a>
                , go to Events section and browse through the list. Select the
                desired events and pay through the site.
              </span>
            </li>
            <li className="flex items-start py-1">
              <span className="my-1">
                <Hexagon color="#7f0600" />
              </span>
              <span className="pl-2">
                Once paid, you can add more events without incurring any further
                cost.
              </span>
            </li>
            <li className="flex items-start py-1">
              <span className="my-1">
                <Hexagon color="#7f0600" />
              </span>
              <span className="pl-2">
                Registered and selected events can be found under the Profile
                section.
              </span>
            </li>

            <li className="flex items-start py-1">
              <span className="my-1">
                <Hexagon color="#7f0600" />
              </span>
              <span className="pl-2">
                Receiving challan from the EDGE room is a must for both online
                and offline mode of transaction.
              </span>
            </li>
            <li className="flex items-start py-1">
              <span className="my-1">
                <Hexagon color="#7f0600" />
              </span>
              <span className="pl-2">Once paid, money won't be refunded.</span>
            </li>
          </ul>
        </div>
      </div>

      <div className="flex justify-center items-center mt-8 mb-4">
        <Link to="/events">
          <button className="nav-right-btn text-white">Register Now!</button>
        </Link>
      </div>

      <hr className="mt-8 mb-0 mx-28 h-[2px] rounded border-0 bg-gray-500" />
    </div>
  );
}
