import EventDetailsBg from "components/events/EventDetailsBg";
import Nav from "components/nav/Navbar";
import React, { useState } from "react";
import "styles/clubs.css";
import ClubCard from "./ClubCard";
import ClubData from "data/ClubsCard.json";
import Lottie from "react-lottie";
import animation from "lotties/about";
import Footer from "components/footer/Footer";
import FooterDev from "components/footer/FooterDev";
import loadergif from "../../assets/loader2025-trans.gif";
import HomeNav from "components/nav/HomeNav";

export default function Clubs() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="relative">
      <EventDetailsBg />
      <HomeNav />
      <div className="mt-16 px-6">
        <div className="text-3xl flex flex-col md:flex-row items-center justify-evenly ">
          <div className="flex items-center justify-center text-center">
            <h1
              style={{ fontFamily: "SKCuber", fontWeight: "300" }}
              className=" text-center text-[1.5rem] sm:text-[2rem] lg:text-[5rem] text-gray-50"
            >
              Ab<span className="text-red">out U</span>s
            </h1>
          </div>
          <div className="flex items-center justify-center h-[250px] w-[250px] sm:h-[300px] sm:w-[300px] lg:h-[400px] lg:w-[400px] pointer-events-none">
            <img
              src={loadergif}
              alt="AboutUS"
              className="h-[50vh] brightness-150"
            />
          </div>
        </div>
        <div className="flex justify-center">
          <Description />
        </div>
      </div>

      <div className="w-full h-full py-12">
        <div className="w-full">
          <div className="flex justify-center w-full text-gray-50 pb-16 text-[4rem] font-black-hawk ">
            Ou<span className="text-red">r Clu</span>bs
          </div>
          <div className="club-section">
            {ClubData.map((item, idx, arr) => {
              return (
                <ClubCard key={item.id} {...item} position={idx - arr.length} />
              );
            })}
          </div>
        </div>
      </div>

      <Footer />
      <FooterDev />
    </div>
  );
}

const Description = () => {
  const [showmore, setShowmore] = useState(false);

  return (
    <div className="flex flex-col items-center w-full">
      {/* About Us */}
      <div className="flex flex-col items-center w-full text-gray-50 pb-8">
        <p className="px-4 py-4 text-md sm:text-base md:px-16 lg:px-24 text-justify">
          The official Science and Technology club of Techno Main Salt Lake –
          Geekonix – was founded in 2005, embarking on a journey to inspire
          young minds and foster technological excellence in a collaborative
          environment. Over the years, our members have continuously pushed the
          boundaries of innovation, creativity, and ideation, setting new
          milestones in the field of technology. Geekonix thrives on cultivating
          skills and nurturing talent through healthy competitions across
          diverse domains. Under its umbrella, we have 12 sub-clubs dedicated to
          areas such as gaming, coding, robotics, entrepreneurship, photography,
          debating, and more. The pinnacle of our initiatives is EDGE, the
          annual techno-management fest of Techno Main Salt Lake, where we bring
          together 40+ technical events over an exhilarating three-day
          extravaganza. Alongside intense competitions, EDGE NIGHTS offer a
          vibrant escape with fun-filled and entertaining evenings. With
          participants from across the country, EDGE is a battleground where
          passion meets performance, and only the best emerge victorious.
        </p>
      </div>
      {/* Our Vision */}
      <div className="flex flex-col items-center w-full text-gray-50 pb-8">
        <div className="text-[4rem] font-black-hawk text-center">
          Ou<span className="text-red">r Vis</span>ion
        </div>
        <p className="px-4 py-4 text-md sm:text-base md:px-16 lg:px-24 text-justify">
          Igniting creativity and connection in the tech community, fostering a
          culture of inclusivity and progress.
        </p>
      </div>

      {/* Our Mission */}
      <div className="flex flex-col items-center w-full text-gray-50 pb-8">
        <div className="text-[4rem] font-black-hawk text-center">
          Ou<span className="text-red">r Mis</span>sion
        </div>
        <p className="px-4 py-4 text-md sm:text-base md:px-16 lg:px-24 text-justify">
          To cultivate talent, and continuous learning, where tech professionals
          and enthusiasts can collaborate, and transform their communities to
          create lasting impact.
        </p>
      </div>
    </div>
  );
};
