import React from "react";
import { HexaGon } from "components/events/Grid";

const style = ({ size, position, color }) => {
  const dim = size + "px";
  return {
    width: dim,
    height: dim,
    // backgroundColor: color,
    position: "absolute",
    top: position.top + "px",
    left: position.left + "px",
    transition: "all 0.1s ease",
  };
};

const Square = (props) => (
  <div style={style(props)}>
    <HexaGon style={{ backgroundColor: props.color }} />
  </div>
);

export default Square;
