import React from "react";
import { ExpandCircleDown } from "@mui/icons-material";
import styles from "styles/edge_game.module.css";
import { UP, DOWN, LEFT, RIGHT } from "../helpers/constants";

function Controls({ handlePlayerMovement }) {
  //   const [up, setup] = useState(false);
  //   const [down, setdown] = useState(false);
  //   const [left, setleft] = useState(false);
  //   const [right, setright] = useState(false);

  //   useEffect(() => {
  //     const timer = setInterval(() => {
  //       handleInterval();
  //       //   console.log("up");
  //     }, 1000);

  //     return () => {
  //       if (timer) clearInterval(timer);
  //     };
  //   }, []);

  //   const handleInterval = () => {
  //     // console.log(up, down, right, left);
  //     if (!(up || down || right || left)) return;

  //     console.log("inside", up, down, right, left);

  //     handlePlayerMovement({
  //       dir: "",
  //       top: (up ? 1 : 0) + (down ? -1 : 0),
  //       left: (right ? 1 : 0) + (left ? -1 : 0),
  //     });
  //   };

  const handleMovement = (dir) => {
    let newDirection = null;

    switch (dir) {
      case "LEFT":
        newDirection = { top: 0, left: -1, dir: LEFT };
        break;
      case "UP":
        newDirection = { top: -1, left: 0, dir: UP };
        break;
      case "RIGHT":
        newDirection = { top: 0, left: 1, dir: RIGHT };
        break;
      case "DOWN":
        newDirection = { top: 1, left: 0, dir: DOWN };
        break;
      default:
        return;
    }
    // setTimeout(() => {
    //   handlePlayerMovement(newDirection);
    // }, 200);
    handlePlayerMovement(newDirection);
  };

  return (
    <div className={styles.controls}>
      <div className={styles.top}>
        <ExpandCircleDown
          className={`rotate-180 scale-[2] ${styles.icon}`}
          onClick={() => handleMovement("UP")}
        />
      </div>

      <div className={styles.middle}>
        <div>
          <ExpandCircleDown
            className={`rotate-90 scale-[2] ${styles.icon}`}
            // onMouseDown={() => setleft(true)}
            // onMouseUp={() => setleft(false)}
            onClick={() => handleMovement("LEFT")}
          />
        </div>
        <div>
          <ExpandCircleDown
            className={`-rotate-90 scale-[2] ${styles.icon}`}
            // onMouseDown={() => setright(true)}
            // onMouseUp={() => setright(false)}
            onClick={() => handleMovement("RIGHT")}
          />
        </div>
      </div>

      <div className={styles.bottom}>
        <ExpandCircleDown
          className={`scale-[2] ${styles.icon}`}
          //   onMouseDown={() => setdown(true)}
          //   onMouseUp={() => setdown(false)}
          onClick={() => handleMovement("DOWN")}
        />
      </div>
    </div>
  );
}

export default Controls;
