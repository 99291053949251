import React from "react";

const style = (dimension) => {
  const dim = dimension + "px";
  return {
    width: dim,
    height: dim,
    border: "1px solid #d00b02",
    position: "relative",
    margin: "25px auto",
    overflow: "hidden",
  };
};

const Board = ({ dimension, children }) => (
  <div style={style(dimension)}>{children}</div>
);

export default Board;
