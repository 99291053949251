import EventDetailsBg from "components/events/EventDetailsBg";
import Nav from "components/nav/Navbar";
import React, { useState } from "react";
import "styles/gallery.css";
import GalleryItem from "./GalleryItem";
import GalleryData from "data/Gallery.json";
import FooterDev from "components/footer/FooterDev";
import { Lightbox } from "react-modal-image";

export default function Gallery() {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState({});

  const closeLightbox = () => {
    setOpen(false);
    setImage({});
  };

  const openLightbox = (imgData) => {
    setOpen(true);
    setImage(imgData);
  };

  return (
    <div className="relative">
      <EventDetailsBg />
      <Nav />
      <div className="mt-6 mx-4">
        <div className="grid gap-2 p-1 duration-[0.3s] gallery">
          {GalleryData.map((item, _idx) => {
            return (
              <GalleryItem
                key={_idx.toString()}
                {...item}
                openLightbox={openLightbox}
              />
            );
          })}
        </div>
      </div>

      {open && (
        <Lightbox
          medium={image.url}
          large={image.url}
          alt={image.caption}
          onClose={closeLightbox}
          hideDownload={true}
          hideZoom={true}
        />
      )}

      <FooterDev />
    </div>
  );
}
