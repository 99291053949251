import React, { useEffect, useRef, useState } from "react";
import "styles/hex_grid.css";

export const HexaGon = ({ children, className, style, onClick }) => {
  return (
    <li className="hex">
      <div className="hexIn">
        <div
          className={`${className || ""}  hexLink`}
          onClick={onClick}
          style={style}
        >
          {children}
        </div>
      </div>
    </li>
  );
};

const Grid = ({ children }) => {
  const [width, setWidth] = useState(0);
  const [maxHexInaRow, setMaxHexInaRow] = useState(0);
  const [gridFormula, setGridFormula] = useState("");
  const [hexWidth, setHexWidth] = useState(100);
  const [padding, setPadding] = useState(27);

  const mainContRef = useRef(null);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setWidth(entries[0].target.clientWidth);
    });

    if (mainContRef.current) {
      observer.observe(mainContRef.current);
      setWidth(mainContRef.current.clientWidth);
    }

    return () => {
      observer.disconnect();
    };
  }, [mainContRef]);

  useEffect(() => {
    if (width >= 1201) {
      setMaxHexInaRow(5);
    }

    if (width >= 901 && width < 1201) {
      setMaxHexInaRow(4);
    }

    if (width >= 601 && width < 901) {
      setMaxHexInaRow(3);
    }
    if (width < 601) {
      setMaxHexInaRow(2);
    }
  }, [width, children.length]);

  useEffect(() => {
    if (children.length < maxHexInaRow) {
      setMaxHexInaRow(children.length);
      return;
    }
    setGridFormula(`${2 * maxHexInaRow - 1}n + ${maxHexInaRow + 1}`);
    setHexWidth(100 / maxHexInaRow);
    setPadding(27 / maxHexInaRow);
  }, [children.length, maxHexInaRow]);

  return (
    <>
      <style>
        {`
        .hexGrid {
           padding-bottom: ${padding}%;
        }
        .hex {
          width: ${hexWidth}%;
        }
        .hex:nth-child(${gridFormula}) {
          margin-left: ${hexWidth / 2}%;
        }
      `}
      </style>
      <ul className="hexGrid" ref={mainContRef}>
        {children}
      </ul>
    </>
  );
};

export default Grid;
