import React from "react";
import styles from "styles/sponsors.module.css";

export default function SponsorCard({ data }) {
  const onClickHandler = () => {
    if (data.link && data.link !== "." && data.link !== " ") {
      window.open(data.link, "_blank");
    }
  };

  return (
    <div className={styles.gridItem}>
      <div className="sponsor-card">
        <div className="imgBx">
          <img
            src={`https://edge-results.s3.ap-south-1.amazonaws.com/sponsor/${data._id}.jpg`}
            alt=""
          />
        </div>
        <div className="contentBx">
          <h2>{data.name}</h2>
          <div className="description">
            <h3>{data.tag}</h3>
          </div>
          {data.link && data.link !== "." && data.link !== " " && (
            <p className="mb-0 link" onClick={onClickHandler}>
              More Info
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
