import Footer from "components/footer/Footer";
import HomeNav from "components/nav/HomeNav";
import { NavLink } from "react-router-dom";
import Omnitrix from "./Omnitrix";
import CountdownTimer from "./CountdownTimer"; // Import the countdown timer

const Home = () => {
  return (
    <>
      <div className="relative w-full h-screen overflow-hidden">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          autoPlay
          loop
          muted
        >
          <source src="/videos/Home-bg-video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <HomeNav />
        <br />
        <div className="absolute inset-0 flex flex-col items-center justify-center p-10 text-center text-white space-y-5 bg-black/50">
          <h1 className="font-black-hawk mt-12 hidden md:block text-gray-50 text-9xl animate__animated animate__fadeIn">
            We<span className="ml-8 text-red mr-8">Are</span>The
            <span className="ml-8 text-red mr-8">Revolution</span>
          </h1>

          <h1 className="font-black-hawk md:hidden text-gray-50 text-6xl animate__animated animate__fadeIn text-center leading-tight">
            <span className="block sm:inline">
              We<span className="mx-4 text-red">Are</span>The
            </span>
            <span className="block text-red mt-2 mx-4 sm:mt-0">Revolution</span>
          </h1>

          <br />
          <NavLink
            to="/registernow"
            className="relative flex justify-center items-center min-w-[150px] min-h-[45px] px-6 py-2 text-lg font-semibold tracking-wide text-white uppercase transition-all duration-300 rounded-lg border-2 border-red bg-gradient-to-r from-red to-gray-800 hover:from-red hover:to-red hover:shadow-red-500/50 hover:shadow-lg active:scale-95 overflow-hidden"
          >
            <span className="absolute inset-0 bg-white/10 opacity-0 transition-opacity duration-300 group-hover:opacity-20"></span>
            Register Now
            <span className="absolute -inset-[150%] bg-white opacity-10 rotate-45 transition-transform duration-700 group-hover:translate-x-[150%]"></span>
          </NavLink>

          <div className="mt-6 p-4 bg-white/10 backdrop-blur-lg rounded-2xl shadow-lg">
            <iframe
              className="w-[80vw] md:w-[560px] h-[200px] md:h-[315px] rounded-lg"
              src="https://www.youtube.com/embed/LzmQN1cm5Yo?autoplay=1&loop=1&playlist=LzmQN1cm5Yo"
              title="YouTube video"
              frameBorder="0"
              allow="accelerometer; loop; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>

      {/* Next section */}
      <div className="relative w-full h-full py-16 md:py-24 flex items-center justify-center bg-black text-white">
        <div className="container mx-auto px-6 md:px-12 lg:px-16">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <Omnitrix />

            <div className="space-y-8">
              <div className=" inline-block">
                <h3 className="text-4xl md:text-5xl font-extrabold text-white drop-shadow-[0_0_0.1rem_red]">
                  Theme <span className="text-red">Concept</span>
                </h3>
                <div className="absolute left-0 bottom-0 w-2/3 h-[3px] bg-gradient-to-r from-red-500 to-white animate-[pulse_1.5s_infinite]"></div>
              </div>

              <p className="text-lg md:text-xl text-gray-300 leading-relaxed">
                This year, we redefine the hourglass as a compass, emphasizing
                that direction matters as much as time. Our protagonist, ELuFaS,
                journeys through four eras, each symbolizing an aspect of time:
              </p>
              <p className="text-lg md:text-xl text-gray-300">
                🔹 Elysian Era – <br /> Harmony, Copper Core (heart of time){" "}
                <br />
                🔹 Luminous Age – <br /> Radiance, Crystal Shard (light of
                knowledge) <br />
                🔹 Fading Light – <br /> Twilight, Shadow Fragment (direction of
                time) <br />
                🔹 Shadow Age –<br /> Darkness, Steel Needle (progress) <br />
                <br />
                These elements unite to form a compass, illustrating spacetime’s
                four-dimensional fabric, inspired by Einstein’s General
                Relativity. Our theme, NAVI 19° North – Navigating Ages,
                Vectoring Inter-dimensions, signifies moving forward in our 19th
                edition, guiding us through time and space. Even ELuFaS' name
                derives from the eras: E-Elysian, Lu-Luminous, Fa-Fading Light,
                S-Shadow. This is more than a theme—it’s a journey of direction,
                discovery, and destiny.
              </p>

              <button className="px-8 py-3 bg-red hover:bg-red-700 transition-all duration-300 rounded-lg text-white font-semibold shadow-md hover:shadow-red-500/50 transform hover:scale-105">
                <a href="/clubs">Discover More</a>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Countdown Timer placed above the team heading */}
      <div className="flex flex-col justify-center items-center my-10">
        <CountdownTimer />

        <div className="team-heading w-[600px] relative text-center p-6 text-4xl text-red shadow-sm pb-8 mb-8">
          <h1 className="absolute top-1/2 left-1/2 font-black-hawk text-2xl sm:text-3xl md:text-5xl">
            T<span className="text-gray-50">ea</span>M Geek
            <span className="text-gray-50">onix</span>
          </h1>
        </div>
        <img
          className=" mx-2 object-contain"
          src="https://i.postimg.cc/kgxtYywW/geekonix-team.jpg"
          alt="Team Geekonix"
        />
      </div>

      <Footer />
    </>
  );
};

export default Home;
