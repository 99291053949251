import React, { useEffect, useState } from "react";
import axios from "axios";
import { server_api } from "config/config";
import Rules from "./Rules";
import Modal from "@mui/material/Modal";
import EventDetailsBg from "./EventDetailsBg";
import EventsDetailsHeader from "./EventsDetailsHeader";
import Tnc from "components/tnc/Tnc";
import Comingsoon from "./comingsoon/Comingsoon";
// import { getEventData } from "utils/fetchEvents";

const clubData = [
  {
    _id: "626edc2f5c66fb363925ecb0",
    eventCode: "CUR001",
    title: "FLAWLESS",
    club: "curbrain",
    image: "/images/event-icons/curbrain/flawless.svg",
  },
  {
    _id: "626edf425c66fb363925ecbd",
    eventCode: "RRC001",
    title: "ROBO RACE",
    club: "rrc",
    image: "/images/event-icons/rrc/roborace.svg",
  },
  {
    _id: "626ee08c5c66fb363925eccd",
    eventCode: "RRC002",
    title: "ROBO SOCCER",
    club: "rrc",
    image: "/images/event-icons/rrc/robosoccer.svg",
  },
  {
    _id: "626ee2d65c66fb363925ece3",
    eventCode: "RRC004",
    title: "MINI WAR",
    club: "rrc",
    image: "/images/event-icons/rrc/MiniRobowar.svg",
  },
  {
    _id: "626ee4cd5c66fb363925ecf7",
    eventCode: "RRC005",
    title: "WAR",
    club: "rrc",
    image: "/images/event-icons/rrc/robowar.svg",
  },
  {
    _id: "626ee6185c66fb363925ed0b",
    eventCode: "CUR002",
    title: "CRYPTOQUEST",
    club: "curbrain",
    image: "/images/event-icons/curbrain/cryptoquest.svg",
  },
  {
    _id: "626ee7435c66fb363925ed13",
    eventCode: "ELE001",
    title: "NIRMAAN",
    club: "elevation",
    image: "/images/event-icons/elevation/NIRMAAN.svg",
  },
  {
    _id: "626ee85c5c66fb363925ed1f",
    eventCode: "CUR003",
    title: "BUGHUNT",
    club: "curbrain",
    image: "/images/event-icons/curbrain/bughunt.svg",
  },
  {
    _id: "626eedd35c66fb363925ed4b",
    eventCode: "CUR004",
    title: "GEEKATHON",
    club: "curbrain",
    image: "/images/event-icons/curbrain/geekathon.svg",
  },
  {
    _id: "626eef035c66fb363925ed54",
    eventCode: "ELE002",
    title: "PAPER-O-VATION",
    club: "elevation",
    image: "/images/event-icons/elevation/PAPEROVATION.svg",
  },
  {
    _id: "626ef4f65c66fb363925ed81",
    eventCode: "CIIC001",
    title: "BOARDROOM",
    club: "ciic",
  },
  {
    _id: "626ef5e75c66fb363925ed86",
    eventCode: "CIIC002",
    title: "BUSINESS MODEL PLAN",
    club: "ciic",
  },
  {
    _id: "626ef7065c66fb363925ed8d",
    eventCode: "CR001",
    title: "MEKANIX",
    club: "createit",
  },
  {
    _id: "626ef7515c66fb363925ed9d",
    eventCode: "CIIC003",
    title: "B-QUIZ",
    club: "ciic",
  },
  {
    _id: "626ef82c5c66fb363925edac",
    eventCode: "CIIC004",
    title: "BRAND IT",
    club: "ciic",
  },
  {
    _id: "626efa3a5c66fb363925edc0",
    eventCode: "FFF001",
    title: "FOOD PHOTOGRAPHY(Online)",
    club: "foodforfun",
  },
  {
    _id: "626efb465c66fb363925edc8",
    eventCode: "INF001",
    title: "SHOOT-M-UP",
    club: "infocus",
    image: "/images/event-icons/infocus/shootmup.svg",
  },
  {
    _id: "626efc1b5c66fb363925edd5",
    eventCode: "INF002",
    title: "PHOTO STORY",
    club: "infocus",
    image: "/images/event-icons/infocus/Photo-story.svg",
  },
  {
    _id: "626efca45c66fb363925eddd",
    eventCode: "FFF003",
    title: "PITCH THE LABEL(Offline)",
    club: "foodforfun",
  },
  {
    _id: "626efdbf5c66fb363925ede6",
    eventCode: "FFF004",
    title: "FOOD RELAY(Offline)",
    club: "foodforfun",
  },
  {
    _id: "626efeb05c66fb363925edf2",
    eventCode: "INF003",
    title: "CRUMBS",
    club: "infocus",
    image: "/images/event-icons/infocus/Crumbs.svg",
  },
  {
    _id: "626efec15c66fb363925edf6",
    eventCode: "FFF005",
    title: "PROCESS PUZZLE(Offline)",
    club: "foodforfun",
  },
  {
    _id: "626effb75c66fb363925ee04",
    eventCode: "FFF006",
    title: "FOOD DEBATE(Offline)",
    club: "foodforfun",
  },
  {
    _id: "626face05c66fb363925ee8c",
    eventCode: "NEW001",
    title: "METAVERSE OF MADNESS",
    club: "newron",
    image: "/images/event-icons/newron/metaverse.svg",
  },
  {
    _id: "626faedf5c66fb363925ee92",
    eventCode: "NEW002",
    title: "MIND-CRAFT and QUIZZARDRY",
    club: "newron",
    image: "/images/event-icons/newron/mindcraft.svg",
  },
  {
    _id: "626fb3b15c66fb363925eea0",
    eventCode: "NEW003",
    title: "ELECTRONICALLY YOURS",
    club: "newron",
    image: "/images/event-icons/newron/electronically-yours.svg",
  },
  {
    _id: "626fb9385c66fb363925eeca",
    eventCode: "RRC006",
    title: "TRACK HUNTER",
    club: "rrc",
    image: "/images/event-icons/rrc/trackhunter.svg",
  },
  {
    _id: "626fbe5b5c66fb363925eed7",
    eventCode: "RRC007",
    title: "JIGSAW",
    club: "rrc",
    image: "/images/event-icons/rrc/jigsaw.svg",
  },
  {
    _id: "626fc09a5c66fb363925eee3",
    eventCode: "RRC008",
    title: "WALL STREET",
    club: "rrc",
    image: "/images/event-icons/rrc/wallstreet.svg",
  },
  {
    _id: "627031e35c66fb363925f004",
    eventCode: "GD001",
    title: "INTER-ACTION",
    club: "geekdesign",
    image: "/images/event-icons/geek-design/interaction.svg",
  },
  {
    _id: "627036985c66fb363925f03e",
    eventCode: "GD002",
    title: "MOTION CANVAS",
    club: "geekdesign",
    image: "/images/event-icons/geek-design/motion.svg",
  },
  {
    _id: "6270372e5c66fb363925f04c",
    eventCode: "NEW004",
    title: "YOUTH PARLIAMENT",
    club: "newron",
    image: "/images/event-icons/newron/youth-parliament.svg",
  },
  {
    _id: "6270395c5c66fb363925f082",
    eventCode: "GD003",
    title: "PIXEL PLOT",
    club: "geekdesign",
    image: "/images/event-icons/geek-design/pixel.svg",
  },
  {
    _id: "627144085c66fb363925f25f",
    eventCode: "CC001",
    title: "VALORANT TECHNO CHAMPIONS TOUR",
    club: "cybercrusade",
    image: "/images/event-icons/cc/valorant.svg",
  },
  {
    _id: "627146bd5c66fb363925f264",
    eventCode: "CC002",
    title: "BGMI CHAMPIONSHIP",
    club: "cybercrusade",
    image: "/images/event-icons/cc/bgmi.svg",
  },
  {
    _id: "627149655c66fb363925f276",
    eventCode: "CC003",
    title: "FIFA 22",
    club: "cybercrusade",
    image: "/images/event-icons/cc/fifa22.svg",
  },
  {
    _id: "627270385c66fb363925f723",
    eventCode: "CC004",
    title: "CS GO Pro-League",
    club: "cybercrusade",
    image: "/images/event-icons/cc/csgo.svg",
  },
];

export default function EventDetails({ eventCode, close, registered }) {
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState(null);
  const [imageloaderr, setImageloaderr] = useState(false);

  useEffect(() => {
    let source = axios.CancelToken.source();

    async function getData() {
      try {
        const res = await axios.get(`${server_api}/events/${eventCode}`, {
          cancelToken: source.token,
        });

        if (res?.data?.success && res?.data?.event) {
          setEvent(res.data.event);
        } else {
          close("force");
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error("Error fetching event details:", error);
        }
        close("force");
      } finally {
        setLoading(false);
      }
    }

    if (eventCode) {
      getData();
    }

    return () => {
      source.cancel("EventDetails component unmounted.");
    };
  }, [eventCode, close]);

  return (
    <Modal open={true}>
      <div className="w-full h-full min-h-screen text-gray-50 py-0 outline-none relative event_details_modal">
        <EventDetailsBg />

        {/* SHOWING "LOADING..." AS THE DATA IS BEING FETCHED */}
        {loading ? (
          <div className="w-full h-full min-h-screen flex items-center justify-center">
            <p>Loading...</p>
          </div>
        ) : event ? (
          <>
            <div className="p-1 pb-4 sm:p-8 sm:pt-4 flex flex-col z-10 min-h-screen min-w-screen w-full justify-center">
              <EventsDetailsHeader
                event={event}
                registered={registered}
                close={close}
              />

              <div className="flex px-5 event_details grow">
                <div className="flex justify-center event_img_container px-8 py-4">
                  <img
                    alt=""
                    src="/images/mobile.png"
                    className="w-full z-10"
                  />
                  {!imageloaderr ? (
                    <img
                      src={
                        clubData.find((item) => item.eventCode === eventCode)
                          ?.image
                      }
                      alt="Event Poster"
                      className="z-10 max-h-[75vh] h-auto max-w-full event-poster-img"
                      onError={() => setImageloaderr(true)}
                    />
                  ) : (
                    <Comingsoon />
                  )}
                </div>

                <div className="grow">
                  <div className="z-10">
                    <div className="flex flex-col pb-5 text-center relative z-10">
                      <h3 className="text-2xl event_glitch_sub text-red-300">
                        {clubData.subtitle}
                      </h3>
                      <h3 className="-ml-14 text-2xl glitch_txt">
                        {clubData.subtitle}
                      </h3>
                      <h1 className="text-5xl event_glitch_title pt-4 text-red-300">
                        {clubData.title}
                      </h1>
                      <h1 className="-mr-36 text-5xl glitch_txt">
                        {clubData.title}
                      </h1>
                    </div>

                    <div className="flex justify-evenly z-10">
                      <div className="w-full text-justify event_details_text z-10 text-red-300">
                        <p>{event.desc}</p>
                        <Rules data={event.rules} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-[#7f0600] m-0 px-8 sm:fixed sm:bottom-0 py-3 flex w-full items-center justify-center z-10 text-center event_details_contact">
              For Queries, please contact:
              <div className="flex flex-col md:flex-row">
                {event?.contacts?.map((e, _idx) => (
                  <ContactCard key={_idx.toString()} data={e} />
                ))}
                <Tnc />
              </div>
            </div>
          </>
        ) : (
          <p className="text-center text-red-500">Event not found</p>
        )}
      </div>
    </Modal>
  );
}

function ContactCard({ data }) {
  return (
    <div className="px-4 flex justify-center">
      <p className="px-2">{data.name}</p>
      <p className="px-2">{data.number}</p>
    </div>
  );
}
