import React from "react";
import styled from "@emotion/styled";
import { useSearchParams } from "react-router-dom";

const Container = styled.div`
  animation-delay: ${(props) => props.animationDelay};

  &::before {
    background-image: url(${(props) => props.image});
  }
`;

const EventItem = ({ data, idx }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  function onClickHanlder() {
    if (data.type === "club") {
      searchParams.set("club", data.name);
      setSearchParams(searchParams);
    } else {
      searchParams.set("event", data.eventCode);
      setSearchParams(searchParams);
    }
  }

  const img = getImageUrl(data);

  return (
    <Container
      className="event-eventPoster from-cyan-500 to-blue-500 min-w-[100vw] md:min-w-[35vw] border border-[rgba(55,55,55,0.8)] bg-[rgba(36,36,36,0.5)] h-full shadow-xl mx-[2px] flex flex-col items-start text-white text-3xl"
      image={img}
      animationDelay={`${idx * 0.2}s`}
      onClick={onClickHanlder}
    >
      <div className="flex gap-8 m-8 mx-10 items-center event-clubName">
        {/* <img
          src='https://edge-results.s3.ap-south-1.amazonaws.com/events/b8c4ff9b-8257-4c9a-9505-7d7350449d92.jpg'
          alt=''
          width={50}
        /> */}
        <span>{data.title}</span>
      </div>
    </Container>
  );
};

const getImageUrl = (data) => {
  // console.log(data);

  if (data.type === "club" && data.image) return data.image;

  if (data.type === "event" && data._id) return 1;

  return "https://i.ibb.co/GCVfL0Z/shootmup.png";
};

export default EventItem;
