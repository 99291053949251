import React from "react";
import loaderGif from "../../assets/loader2025.gif";
import "styles/loader.css";

export default function HomeLoader() {
  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-black/90 flex items-center justify-center flex-col">
      <div className="">
        <img src={loaderGif} alt="" className="w-screen sm:w-[600px] h-auto" />
      </div>
      <div className="font-black-hawk text-center text-gray-50 text-4xl pt-2">
        Lo<span className="text-red">adi</span>ng
        <span className="loading-dots">...</span>
      </div>
    </div>
  );
}
