import React from "react";
import { ReactComponent as Browser } from "assets/icons/browsericon.svg";
import { ReactComponent as Youtube } from "assets/icons/yticon.svg";
import { ReactComponent as Fb } from "assets/icons/fbicon.svg";
import { ReactComponent as Insta } from "assets/icons/instaicon.svg";
import { ReactComponent as Linkedin } from "assets/icons/linkedinicon.svg";
import { ReactComponent as Twitter } from "assets/icons/twittericon.svg";
import { ReactComponent as Location } from "assets/icons/location.svg";
import { ReactComponent as Mail } from "assets/icons/email.svg";
import Tooltip from "@mui/material/Tooltip";

function Footer() {
  return (
    <div className="bg-secondary_black">
      <div className="mb-4 overflow-auto footer-social-icons">
        <ul className="flex justify-center items-center w-full pt-5">
          <li className="footer-icons drop-shadow-[0_0_0.1rem_red]">
            <a href="https://geekonix.org/" target="_blank" rel="noreferrer">
              <Tooltip title="Visit geekonix.org" arrow>
                <Browser className="h-10 w-10 footer_icons_img" />
              </Tooltip>
            </a>
          </li>
          <li className="footer-icons drop-shadow-[0_0_0.2rem_#ff0000]">
            <a
              href="https://www.youtube.com/channel/UCSwFemGqe1XRmVlg1jRNJYw"
              target="_blank"
              rel="noreferrer"
            >
              <Tooltip title="Subscribe to our Youtube channel" arrow>
                <Youtube className="h-10 w-10 footer_icons_img" />
              </Tooltip>
            </a>
          </li>
          <li className="footer-icons drop-shadow-[0_0_0.2rem_#2D88FF]">
            <a
              href="https://www.facebook.com/geekonix/"
              target="_blank"
              rel="noreferrer"
            >
              <Tooltip title="Like our Facebook page" arrow>
                <Fb className="h-10 w-10 footer_icons_img" />
              </Tooltip>
            </a>
          </li>
          <li className="footer-icons drop-shadow-[0_0_0.2rem_#8a3ab9]">
            <a
              href="https://www.instagram.com/geekonix/"
              target="_blank"
              rel="noreferrer"
            >
              <Tooltip title="Follow us on Instagram" arrow>
                <Insta className="h-10 w-10 footer_icons_img" />
              </Tooltip>
            </a>
          </li>
          <li className="footer-icons drop-shadow-[0_0_0.2rem_#0072b1]">
            <a
              href="https://www.linkedin.com/company/geekonix-edge"
              target="_blank"
              rel="noreferrer"
            >
              <Tooltip title="Follow us on LinkedIn" arrow>
                <Linkedin className="h-10 w-10 footer_icons_img" />
              </Tooltip>
            </a>
          </li>
          <li className="footer-icons drop-shadow-[0_0_0.2rem_#00ACEE]">
            <a
              href="https://twitter.com/GeekonixEdge"
              target="_blank"
              rel="noreferrer"
            >
              <Tooltip title="Follow us on Twitter" arrow>
                <Twitter className="h-10 w-10 footer_icons_img" />
              </Tooltip>
            </a>
          </li>
        </ul>
      </div>
      <div className="flex flex-col md:flex-row justify-between text-gray-50 px-10 pb-5">
        <div className="flex text-left flex-col">
          <h1 className=" before:bg-red before:h-0.5 before:w-16 before:left-0 before:-bottom-1 text-4xl ">
            Contact Us
          </h1>
          <p className="py-2 flex flex-col text-sm md:text-base">
            For any queries, please contact:
            <a
              href="tel:+91 93306 25233"
              className="hover:text-red transition-all"
            >
              {" "}
              Akshita: +91 93306 25233
            </a>
            <a
              href="tel: +91 70449 03833"
              className="hover:text-red transition-all"
            >
              {" "}
              Swapnil: +91 70449 03833
            </a>
          </p>
        </div>
        <div className="flex text-right flex-col ml-5">
          <h1 className="before:content-[''] before:absolute before:bg-red before:h-0.5 before:w-16 before:right-0 before:-bottom-1 text-4xl relative">
            Geekonix
          </h1>
          <div className="py-2">
            <ul className="md:ml-10 text-sm md:text-base">
              <li
                // onClick={openLocation}
                className="flex justify-between items-center py-1 w-full"
              >
                <span className="grow text-right">
                  EM-4/1, Sector V, Salt Lake,
                  <br />
                  Kolkata, West Bengal 700091
                  {/* Vill &lt Post - Ahimane, AHIMANA
                  <br />
                  Allahabad road, SULTANPUR
                  <br />
                  Ahimame bazar, SULTANPUR (OUDH) */}
                </span>
                <Location className="h-10 w-15 -mr-8 -ml-[20px]" />
              </li>
              <li className="flex justify-between items-center py-1">
                <span className="grow text-right">
                  <a
                    href="mailto:geekonix.tmsl@ticollege.org"
                    className="hover:text-red"
                  >
                    geekonix.tmsl@ticollege.org
                  </a>
                </span>
                <Mail className="h-8 w-19 pl-4" />
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* <div className="text-gray-50 flex justify-around items-center text-sm pt-5 md:text-base">
        <ul className="flex justify-evenly w-4/5">
          <li className="mx-7 hover:text-red transition-all">
            <a href="/tnc.html" target="_blank" rel="noreferrer">
              Terms and Condition
            </a>
          </li>
          <li className="mx-7 hover:text-red transition-all">
            <a href="/privacyPolicy.html" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
          </li>
          <li className="mx-7 hover:text-red transition-all">
            <a href="/refundPolicy.html" target="_blank" rel="noreferrer">
              Refund Policy
            </a>
          </li>
        </ul>
      </div> */}

      {/* <div>
        <div className="text-gray-50 py-5 flex justify-center text-sm md:text-base">
          <p className="flex items-center">
            <span className="text-2xl pb-[3px] mr-[4px]">&copy;</span>
            <strong>Geekonix</strong>. All rights reserved.
          </p>
        </div>
      </div> */}
    </div>
  );
}

export default Footer;
