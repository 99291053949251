import React from "react";
import "styles/team.css";
import coreTeam from "data/CoreTeam.json";
import devTeam from "data/DevTeam.json";
import subHead from "data/subheads.json";
import Nav from "components/nav/Navbar";
import Teamgrid from "./Teamgrid";
import EventDetailsBg from "components/events/EventDetailsBg";
import GridItem from "./TeamGridItem";
import FooterDev from "components/footer/FooterDev";
import HomeNav from "components/nav/HomeNav";

export default function Team() {
  return (
    <div className="relative">
      <EventDetailsBg />
      <HomeNav />
      <div className="mb-12 px-8 py-4">
        {/* Core Team Section */}
        <div className="mt-16">
          <div className="w-full p-1">
            <div className="team-heading relative text-center p-6 text-4xl text-red shadow-sm pb-8 mb-8">
              <h1
                className="absolute top-1/2 left-1/2 text-2xl sm:text-3xl md:text-5xl"
                style={{ fontFamily: "SKCuber", fontWeight: "300" }}
              >
                C<span className="text-gray-50">OR</span>E TE
                <span className="text-gray-50">AM</span>
              </h1>
            </div>
          </div>
          <Teamgrid>
            {coreTeam.map((e) => (
              <GridItem key={e.id} {...e} />
            ))}
          </Teamgrid>
        </div>

        {/* Development Team Section */}
        <div className="mt-16">
          <div className="w-full p-1">
            <div className="team-heading relative text-center p-6 text-4xl text-red shadow-sm pb-8 mb-8">
              <h1
                className="absolute w-[1000px] top-1/2 left-1/2 text-2xl sm:text-3xl md:text-5xl"
                style={{ fontFamily: "SKCuber", fontWeight: "300" }}
              >
                DEV<span className="text-gray-50">ELOP</span>MENT TE
                <span className="text-gray-50">AM</span>
              </h1>
            </div>
          </div>
          <Teamgrid>
            {devTeam.map((e) => (
              <GridItem key={e.id} {...e} />
            ))}
          </Teamgrid>
        </div>
        {/* Subheads Team Section */}
        <div className="mt-16">
          <div className="w-full p-1">
            <div className="team-heading relative text-center p-6 text-4xl text-red shadow-sm pb-8 mb-8">
              <h1
                className="absolute top-1/2 left-1/2 text-2xl sm:text-3xl md:text-5xl"
                style={{ fontFamily: "SKCuber", fontWeight: "300" }}
              >
                S<span className="text-gray-50">ub</span>cl
                <span className="text-gray-50">ubs</span>
              </h1>
            </div>
          </div>
          <Teamgrid>
            {subHead.map((e) => (
              <GridItem key={e.id} {...e} />
            ))}
          </Teamgrid>
        </div>
        <div className="flex flex-col justify-center items-center  my-10 ">
          <div className="team-heading w-[600px] relative text-center p-6 text-4xl text-red shadow-sm pb-8 mb-8">
            <h1 className="absolute top-1/2 left-1/2 font-black-hawk  text-2xl sm:text-3xl md:text-5xl">
              T<span className="text-gray-50">ea</span>M Geek
              <span className="text-gray-50">onix</span>
            </h1>
          </div>
          <img src="https://i.postimg.cc/kgxtYywW/geekonix-team.jpg" alt="" />
        </div>
      </div>

      <FooterDev />
    </div>
  );
}
