import Footer from "components/footer/Footer";
import HomeNav from "components/nav/HomeNav";

const DemoHome = () => {
  return (
    <>
      <div className="relative w-full h-screen overflow-hidden">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          autoPlay
          loop
          muted
        >
          <source src="/videos/Home-bg-video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <HomeNav />
        <br />
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white space-y-5 bg-black/50">
          <h1 className="font-black-hawk hidden md:block  text-gray-50 text-9xl animate__animated animate__fadeIn">
            We<span className="ml-8 text-red mr-8 ">Are</span>The
            <span className="ml-8 text-red mr-8">Revolution</span>
          </h1>
          <h1 className="font-black-hawk md:hidden text-gray-50 text-6xl  animate__animated animate__fadeIn text-center leading-tight">
            <span className="block sm:inline">
              We<span className="mx-4 text-red">Are</span>The
            </span>
            <span className="block text-red mt-2 mx-4 sm:mt-0">Revolution</span>
          </h1>

          {/* 
          <span className="text-white ml-4 font-black-hawk font-bold drop-shadow-[0_0_0.2rem_red] text-3xl sm:text-3xl md:text-4xl">
              EDG<span className="text-red">E</span> 20
              <span className="text-red">25</span>
            </span> */}
          <br />
          <div className="mt-6 p-4 bg-white/10 backdrop-blur-lg rounded-2xl shadow-lg ">
            <iframe
              className="w-[80vw] md:w-[560px] h-[200px] md:h-[315px] rounded-lg"
              src="https://www.youtube.com/embed/LzmQN1cm5Yo?autoplay=1"
              title="YouTube video"
              frameBorder="0"
              allow="accelerometer;loop; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
      {/*next section*/}
      <div className="relative w-full h-full py-16 md:py-24 flex items-center justify-center bg-black text-white">
        <div className="container mx-auto  px-6 md:px-12 lg:px-16">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div className="relative w-full h-[400px] flex items-center justify-center">
              <div className="relative w-80 h-full bg-white/10 backdrop-blur-xl shadow-lg rounded-2xl overflow-hidden border border-red-500">
                <img
                  src="/images/elufus-image.png"
                  alt="Elufus"
                  className="w-full h-full object-contain mix-blend-lighten "
                />

                <div className="absolute inset-0 bg-black/50 hover:bg-transparent transition duration-500"></div>
              </div>
            </div>

            <div className="space-y-8">
              <div className="relative inline-block">
                <h3 className="text-4xl md:text-5xl font-extrabold text-white drop-shadow-[0_0_0.1rem_red] ">
                  About <span className="text-red ">Us</span>
                </h3>
                <div className="absolute left-0 bottom-0 w-2/3 h-[3px] bg-gradient-to-r from-red-500 to-white animate-[pulse_1.5s_infinite]"></div>
              </div>

              <p className="text-lg md:text-xl text-gray-300 leading-relaxed">
                Edge 2025 is here to redefine innovation, creativity, and
                technology. With a bold vision and unstoppable energy, we are
                shaping the future.
              </p>
              <p className="text-lg md:text-xl text-gray-400">
                Join us in an electrifying journey where ideas turn into reality
                and the revolution begins!
              </p>

              <button className="px-8 py-3 bg-red-600 hover:bg-red-700 transition-all duration-300 rounded-lg text-white font-semibold shadow-md hover:shadow-red-500/50 transform hover:scale-105">
                Learn More
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default DemoHome;
