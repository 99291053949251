import React, { useState, useEffect } from "react";

const CountdownTimer = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const targetDate = new Date(new Date().getFullYear(), 3, 4, 0, 0, 0); // April 4th

    const updateTimer = () => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference <= 0) {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / (1000 * 60)) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        });
      }
    };

    updateTimer(); // initial call
    const timerId = setInterval(updateTimer, 1000);
    return () => clearInterval(timerId);
  }, []);

  return (
    <div className="flex justify-center items-center mb-8">
      <div className="days text-center flex flex-col items-start justify-start">
        <div className="text-5xl font-mono font-bold drop-shadow-lg text-red">
          {timeLeft.days}
          <span className="text-white">:</span>
        </div>
        <div className="text-xs font-mono font-bold drop-shadow-lg text-white ">
          Days
        </div>
      </div>

      <div className="Hours text-center flex flex-col items-start justify-center">
        <div className="text-5xl font-mono font-bold drop-shadow-lg text-red">
          {timeLeft.hours}
          <span className="text-white">:</span>
        </div>
        <div className="text-xs font-mono font-bold drop-shadow-lg text-white mx-3">
          Hours
        </div>
      </div>

      <div className="Minutes  text-center flex flex-col items-start justify-center">
        <div className="text-5xl font-mono font-bold drop-shadow-lg text-red">
          {timeLeft.minutes}
          <span className="text-white">:</span>
        </div>
        <div className="text-xs font-mono font-bold drop-shadow-lg text-white mx-2">
          Minutes
        </div>
      </div>

      <div className="Seconds ">
        <div className="text-5xl font-mono font-bold drop-shadow-lg text-red">
          {timeLeft.seconds}
        </div>
        <div className="text-xs font-mono font-bold drop-shadow-lg text-white">
          Seconds
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
