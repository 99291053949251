import { useRef, useEffect } from "react";

export default function useHorizontalScroll() {
  const elRef = useRef();
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e) => {
        if (e.deltaY === 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY * 5,
          behavior: "smooth",
        });
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, []);

  const scrollLeft = () => {
    const el = elRef.current;
    el.scrollTo({
      left: el.scrollLeft + window.innerWidth,
      behavior: "smooth",
    });
  };
  const scrollRight = () => {
    const el = elRef.current;
    el.scrollTo({
      left: el.scrollLeft - window.innerWidth,
      behavior: "smooth",
    });
  };

  return [elRef, scrollLeft, scrollRight];
}
