import { Modal } from "@mui/material";
import React from "react";
import { useState } from "react";
import ClubInfo from "./ClubInfo";
import ClubCardBg from "./ClubCardBg";

function ClubCard({ image, name, data, members, position }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="club-card">
        <div className="face face1 rounded-lg">
          <ClubCardBg />
          <div className="name-logo">
            <img src={image} alt={name} className="club-logo" />
            <h3 className="club-name text-white pl-2">{name}</h3>
          </div>
        </div>
        <div className="face face2 rounded-lg">
          <div className="content">
            <p>{data}</p>
            <br />
          </div>
        </div>
      </div>

      <Modal open={open}>
        <>
          <ClubInfo
            title={name}
            members={members}
            closeModal={() => setOpen(false)}
          />
        </>
      </Modal>
    </>
  );
}

export default ClubCard;
