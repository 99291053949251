import React from "react";
import CloseIcon from "@mui/icons-material/Close";

export default function EventsDetailsHeader({ event, close, registered }) {
  return (
    <div className="w-full flex flex-row py-5 sm:pt-0">
      {event.eventPrice && event.isActive && (
        <div className="font-black-hawk px-4 py-1 flex flex-col sm:flex-row items-center text-xl sm:text-4xl justify-center registration-fee-container">
          <div className="text-lg sm:text-2xl mr-2 tracking-wider">
            <span>Reg</span>
            <span className="text-red">ist</span>
            <span>rati</span>
            <span className="text-red">on </span>
            <span className="hidden sm:inline">
              <span className="text-red">F</span>
              <span>ee{" : "}</span>
            </span>
          </div>

          <div className="w-full sm:w-fit flex justify-start sm:justify-center">
            <div className="block sm:hidden mr-1">
              <span className="text-red">F</span>
              <span>ee{" : "}</span>
            </div>
            <div className="italic translate-y-[3px] pr-2 text-3xl sm:text-4xl">
              &#x20B9;
            </div>

            <div className="font-semibold tracking-wider text-3xl sm:text-4xl">
              <span className="text-red">
                {event.eventPrice.toString().slice(0, 2)}
              </span>
              <span>{event.eventPrice.toString().slice(2)}</span>
            </div>
          </div>
        </div>
      )}

      <div className="grow flex justify-end h-fit items-center event_details_button">
        <button
          className="text-gray-50 bg-red/50 rounded-md border-2 mr-4 sm:mr-8 border-red px-4 py-1 drop-shadow-[0_0_0.3rem_red] hover:bg-transparent transition-all"
          onClick={openRegisterForm}
        >
          Register
        </button>

        <button
          className="text-gray-50 rounded-full border-2 border-red p-1 drop-shadow-[0_0_0.3rem_red] hover:shadow-[2px_4px_1px_0_#7f0600] transition-all mr-1 sm:m-0"
          onClick={close}
          title="Close"
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  );
}

const openRegisterForm = () => {
  window.open(
    "https://docs.google.com/forms/d/e/1FAIpQLScmqmsFbv0o5bC6qSwjBRGfQ4WEobezCQ9ZrJ7UHw7kQtGHTQ/viewform?usp=sf_link",
    "_blank"
  );
};
