import React from "react";
import parse from "html-react-parser";
import "styles/event_rules.css";

export default function Rules({ data }) {
  if (!data) {
    return "";
  }

  return (
    <div className="sm:pb-8">
      <div className="py-4 mt-4">
        <h1 className="text-4xl text-white text-center event_glitch_rule pb-4">
          Rules
        </h1>
        <div className="event-card-rules">{parse(data)}</div>
      </div>
    </div>
  );
}
