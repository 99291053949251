import React from "react";

const ClubInfo = ({ closeModal, title, members }) => {
  return (
    <div className="popup">
      <div className="popup-content rounded-lg pt-4">
        <div className="flex flex-col pl-12 pr-16 pt-4 pb-2 overflow-y-auto"></div>
      </div>
    </div>
  );
};

export default ClubInfo;
