import React from "react";
import Tilt from "react-parallax-tilt";
import club1 from "../../assets/club_cards/cybercrusade.svg";
import club2 from "../../assets/club_cards/ciic.svg";
import club3 from "../../assets/club_cards/createit.svg";
import club4 from "../../assets/club_cards/curbrain.svg";
import club5 from "../../assets/club_cards/elevation.svg";
import club7 from "../../assets/club_cards/foodforfun.svg";
// import club8 from "../../assets/club_cards/funevents.svg";
import club9 from "../../assets/club_cards/geekdesign.svg";
// import club10 from '../../assets/club/GxD-Elevation-Logo-Black.png'
import club11 from "../../assets/club_cards/infocus.svg";
import club12 from "../../assets/club/JUST LIKE THAT BLACK.png";
import club13 from "../../assets/club_cards/newron.svg";
import club14 from "../../assets/club_cards/RRC-White.png";
import club15 from "../../assets/club_cards/Editorial - White.png";

import FooterDev from "components/footer/FooterDev";
import HomeNav from "components/nav/HomeNav";

const AdminDashboard = () => {
  const clubs = [
    {
      image: club1,
      name: "Cyber Crusade",
      url: "https://docs.google.com/spreadsheets/d/15ubqUU2Ea51_RB4gRPocb-suZjAHo-_nHWT-r9dhwF0/edit?usp=sharing",
    },
    {
      image: club2,
      name: "CIIC",
      url: "https://docs.google.com/spreadsheets/d/1DztzUVQL-dwqEDmccjn1XpqrOT8IP63bqsOz5IWen68/edit?usp=drivesdk",
    },
    {
      image: club3,
      name: "Create-IT",
      url: "https://docs.google.com/spreadsheets/d/10GAOS1Cxb9gFLUa0yVJ91cIZT5rFeg5P_uiKpMV8K8s/edit?usp=drivesdk",
    },
    {
      image: club4,
      name: "GDG X Curbrain",
      url: "https://docs.google.com/spreadsheets/d/1Pn7YQkaeXYpi8NR394_CK5-pLVKkN5Aqvw5KBqYl2jg/edit?usp=drivesdk",
    },
    {
      image: club5,
      name: "Elevation",
      url: "https://docs.google.com/spreadsheets/d/1wXU5RjhohA33Z8CWPeAPjPgp8sZ0vC8wsIYJz5qiP2c/edit?usp=drivesdk",
    },
    {
      image: club7,
      name: "Food-For-Fun",
      url: "https://docs.google.com/spreadsheets/d/1jlacoW6tf5n1MKCV683gFcFndNTaqOXSiyXZh3cl3Ac/edit?usp=drivesdk",
    },
    {
      image: club14,
      name: "RRC Autonomous",
      url: "https://docs.google.com/spreadsheets/d/1E3XvY5Zb_qYEiE1cEEuvRusbSvfcffEnCZzq2XfwMJw/edit?usp=drivesdk",
    },
    {
      image: club9,
      name: "Geek-Designs",
      url: "https://docs.google.com/spreadsheets/d/1NkFNG5r8uEKmmrduPlrPJw_QG_BFpehH2dRcoeK2zBk/edit?usp=drivesdk",
    },
    {
      image: club11,
      name: "Infocus",
      url: "https://docs.google.com/spreadsheets/d/1USkkkH4Lchtqio1RdEs5aDOcV_ooM1AEMTMMtsmkYxU/edit?usp=drivesdk",
    },
    {
      image: club12,
      name: "Just-Like-that",
      url: "https://docs.google.com/spreadsheets/d/16hRrv8epQf0XzSN3YCkIJx2c2BcBHvW-Ir2DT5seeGg/edit?usp=drivesdk",
    },
    {
      image: club13,
      name: "Newron",
      url: "https://docs.google.com/spreadsheets/d/18okUPkxVQcdz5Y1JmA0RU24aghrll_rS7TY9v8FAUYo/edit?usp=drivesdk",
    },
    {
      image: club14,
      name: "RRC",
      url: "https://docs.google.com/spreadsheets/d/1bAtwodRBdJGrne4E9OMTGOvAhslqWskCmJ3X0nWwYoM/edit?usp=drivesdk",
    },
    {
      image: club15,
      name: "Editorial",
      url: "https://docs.google.com/spreadsheets/d/1aEiqPBPg9qCM_ylau69AnjyjeYkK0e9wKaP-de-hFx0/edit?usp=drivesd",
    },
  ];
  return (
    <>
      <HomeNav />
      <main className="">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover "
          autoPlay
          loop
          muted
        >
          <source src="/videos/Home-bg-video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <section className="m-10">
          <h1 className="text-center text-7xl text-red font-black-hawk my-5  relative font-bold">
            Admi<span className="text-black ">n</span> Pane
            <span className="text-black ">L</span>
          </h1>
          <div className="flex justify-center items-center mx-auto">
            <div className="flex justify-center items-center flex-wrap  gap-10">
              {clubs.map((club, i) => (
                <div key={i} className="">
                  <Tilt tiltMaxAngleX={2.5} tiltMaxAngleY={1} perspective={500}>
                    <div class="relative  flex flex-col  my-6 bg-gradient-to-tr from-gray-800 to-red_second shadow-sm border border-slate-200 rounded-lg w-96">
                      <div class="relative p-2.5 h-96 overflow-hidden rounded-xl bg-clip-border">
                        <img
                          src={club.image}
                          alt="card-image"
                          class="h-full w-full object-contain rounded-md"
                        />
                      </div>
                      <div class="p-4">
                        <div class="mb-2 flex items-center justify-between">
                          <p class="text-slate-300 text-xl font-semibold">
                            {club.name}
                          </p>

                          <a
                            class="rounded-md w-1/2 mt-2 bg-cyan-600 py-2 px-4 border border-transparent text-center text-sm text-white transition-all shadow-md hover:shadow-lg focus:bg-cyan-700 focus:shadow-none active:bg-cyan-700 hover:bg-cyan-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                            href={club.url}
                            target="_blank"
                          >
                            Manual
                          </a>
                        </div>
                      </div>
                    </div>
                  </Tilt>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
      <FooterDev />
    </>
  );
};

export default AdminDashboard;
