import React from "react";

export default function Loading() {
  return (
    <div className="loading-modal">
      <div className="stage">
        <p>Loading...</p>
        <div className="dot-typing"></div>
      </div>
    </div>
  );
}
